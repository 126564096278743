import React, {useState} from "react"
import { Helmet } from "react-helmet"
import Header from "../components/header"
import Footer from "../components/footer"
import { graphql, Link, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import "../styles/style.css" 
import { FaHome } from "react-icons/fa"
import { FaAngleDown } from "react-icons/fa"
import { FaFilePdf } from "react-icons/fa"


const Actualidad = () => {
  const data = useStaticQuery(graphql`
    query Images_actualidad {
      image: allFile(
        filter: { relativeDirectory: { eq: "actualidad" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  function Noticia1() {window.open ("https://www.comunicae.es/nota/la-migracion-cloud-uno-de-los-servicios-mas-1229135/")}  
  function Noticia2() {window.open ("https://www.diariosigloxxi.com/texto-diario/mostrar/3286230/importancia-ciberseguridad-proteger-ecommerce")}
  function Noticia3() {window.open ("https://www.merca2.es/2021/11/24/puesta-a-punto-para-el-black-friday/")}  
  function Noticia4() {window.open ("https://tiempodenegocios.com/como-mantener-a-salvo-tu-negocio-de-un-ataque-informatico/")}
  function Noticia5() {window.open ("https://www.comunicae.es/nota/qualoom-ofrece-una-guia-detallada-sobre-1232402")}
  function Noticia6() {window.open ("https://www.autonomosyemprendedor.es/articulo/todo-digital/que-es-consultoria-tecnologica-como-puede-ayudar-autonomos-aumentar-ventas/20220214114058026071.html")}
  function Noticia7() {window.open ("https://www.red.es/es/actualidad/noticias/el-gobierno-lanza-la-primera-convocatoria-de-ayudas-del-programa-kit-digital")}
  function Noticia8() {window.open ("https://www.red.es/es/actualidad/noticias/los-agentes-digitalizadores-ya-pueden-adherirse-al-programa-kit-digital")}
  function Noticia9() {window.open ("https://grandesmedios.com/importancia-seguridad-informatica/")}
  function Noticia10() {window.open ("https://www.parqueempresarial.es/la-herramienta-clave-para-las-empresas-del-siglo-xxi-la-nube-de-datos/")}
  function Noticia11() {window.open ("https://www.servicom.es/el-gran-reto-de-la-digitalizacion/")}
  function Noticia12() {window.open ("https://negociosyempresa.com/la-consultoria-tecnologic/")}
  function Noticia13() {window.open ("https://cadenaser.com/cmadrid/2022/06/29/qualoom-una-referencia-en-servicios-cloud-y-consultoria-tecnologica-radio-madrid/")}
  function Noticia14() {window.open ("https://www.comunicae.es/nota/la-digitalizacion-de-las-empresas-un-paso-1237720/")}
  function Noticia15() {window.open ("https://tecnovedosos.com/arquitecturas-ecommerce/")}
  function Noticia16() {window.open ("https://www.yoseomarketing.com/blog/wpo-web-como-mejorar-velocidad-carga-web-plugins-hosting/")}
  function Noticia17() {window.open ("https://www.ticpymes.es/tecnologia/noticias/1136367049504/proteger-sitio-web-de-comercio-electronico-de-piratas-informaticos.1.html")}
  function Noticia18() {window.open ("https://www.yoseomarketing.com/blog/transformacion-digital-que-es-y-como-pueden-ayudarte-empresas-como-qualoom/")}
  function Noticia19() {window.open ("https://comunicae.es/notas-de-prensa/qualoom-un-referente-en-infraestructura-y")}
  function Noticia20() {window.open ("https://www.yoseomarketing.com/blog/las-5-mejores-consultoras-tecnologicas-en-espana/")}
  function Noticia21() {window.open ("https://iat.es/blog/devops-modelo-colaboracion-integracion-administracion-sistemas/")}
  function Noticia22() {window.open("https://www.yoseomarketing.com/blog/plugins-que-garantizan-la-seguridad-en-wordpress/")}
  function Noticia23() {window.open("https://www.elconfidencialdigital.com/articulo/comunicados/qualoom-expertise-permite-conocer-nivel-seguridad-informatica-empresa-sencillo-test-autoevaluacion/20230110191245502719.html")}
  function Noticia24() {window.open("https://tecnobits.net/como-conseguir-un-entorno-cloud-suficientemente-seguro-con-aws/")}
  function Noticia25() {window.open("https://www.socialetic.com/e-commerce-la-practica-imprescindible-para-la-transformacion-digital-de-las-empresas/")}
  function Noticia26() {window.open("https://www.lavozdegalicia.es/noticia/reto-digital/ocio/2023/03/17/brechas-seguridad-informatica-empresas-espanolas/00031679055348649665511.htm")}
  function Noticia27() {window.open("https://comunicae.es/notas-de-prensa/la-inversion-en-servicios-cloud-sigue-siendo")}
  function Noticia28() {window.open("https://digitalmarketingtrends.es/la-necesidad-de-la-ciberseguridad-en-el-proceso-de-transformacion-digital-de-las-empresas/")}
  function Noticia29() {window.open("https://www.revistatransformaciondigital.com/2023/05/22/la-importancia-del-cloud-computing-en-los-procesos-de-transformacion-digital/")}
  function Noticia30() {window.open("https://www.tecnocosas.es/seguridad-informatica-amenazas-mas-comunes-y-la-mejor-estrategia-de-proteccion-para-empresas/")}
  function Noticia31() {window.open("https://comunicae.es/notas-de-prensa/qualoom-la-consultoria-tecnologica-con-la-que")}
  function Noticia32() {window.open("https://rincondelatecnologia.com/que-es-una-consultoria-tecnologica-y-por-que-las-empresas-deben-contar-con-este-servicio-para-crecer/")}
  function Noticia33() {window.open("https://tynmagazine.com/los-riesgos-en-seguridad-informatica-mas-graves-para-las-empresas-en-2023/")}
  function Noticia34() {window.open("https://www.alertadigital.com/2023/09/19/espana-uno-de-los-paises-con-menos-brecha-de-seguridad-informatica-en-empresas/")}
  function Noticia35() {window.open("https://comunicae.es/notas-de-prensa/la-consultoria-tecnologica-en-espana-sigue/")}
  function Noticia36() {window.open("https://www.cinconoticias.com/razon-por-la-que-las-empresas-deben-apoyarse-consultoria-tecnologica/")}
  function Noticia37() {window.open("https://murciaeconomia.com/art/93351/las-cuatro-amenazas-de-ciberseguridad-para-las-empresas")}
  function Noticia38() {window.open("https://rincondelatecnologia.com/trazando-el-exito-empresarial-descubre-el-poder-del-soporte-informatico-24x7-con-qualoom-expertise-technology/")}

  const [title, setTitle] = useState (false)
  const [nuevo, setNuevo] = useState (false)
  const Last = () => {
    if (!nuevo){setNoticia(false);setNovedad(false); setPdf(false);}
    setTitle(!title)
    setNuevo(!nuevo)
    document.getElementById("title").className = ("show");
  }
  const [noticia, setNoticia] = useState(false)
  const News = () => {
    if (!noticia){setNovedad(false); setPdf(false); setTitle(false);}
    setNoticia(!noticia)
    document.getElementById("title").className = ("hide");
    document.getElementById("last-news").className = ("item-novedad show");
    document.getElementById("last-web").className = ("item-novedad hide");
    document.getElementById("last-web2").className = ("item-novedad hide");
  }
  const [novedad, setNovedad] = useState(false)
  const Web = () => {
    if (!novedad) {setNoticia(false); setPdf(false); setTitle(false);}
    setNovedad(!novedad)
    document.getElementById("title").className = ("hide");
    document.getElementById("last-news").className = ("item-novedad hide");
    document.getElementById("last-web").className = ("item-novedad show");
    document.getElementById("last-web2").className = ("item-novedad show");
  }
  const [pdf, setPdf] = useState(false)
  const Contenidos = () => {
    if (!pdf) {setNoticia(false); setNovedad(false); setTitle(false);}
    setPdf(!pdf)
    document.getElementById("title").className = ("hide");
    document.getElementById("last-news").className = ("item-novedad hide");
    document.getElementById("last-web").className = ("item-novedad hide");
    document.getElementById("last-web2").className = ("item-novedad hide");
  }
  const NewsMobile = () => {
    if (!noticia) {setNovedad(false); setPdf(false); setTitle(false);}
    setNoticia(!noticia)
    document.getElementById("last-news").classList.toggle("show");
    document.getElementById("last-web").classList.toggle("hide");
    document.getElementById("last-web2").classList.toggle("hide");
  }
  const WebMobile = () => {
    if (!novedad) {setNoticia(false); setPdf(false); setTitle(false);}
    setNovedad(!novedad)
    document.getElementById("last-news").classList.toggle("hide");
    document.getElementById("last-web").classList.toggle("show");
    document.getElementById("last-web2").classList.toggle("show");
  }
  const ContenidosMobile = () => {
    if (!pdf) {setNoticia(false); setNovedad(false); setTitle(false);}
    setPdf(!pdf)
    document.getElementById("last-news").classList.toggle("hide");
    document.getElementById("last-web").classList.toggle("hide");
    document.getElementById("last-web2").classList.toggle("hide");
  }


return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Actualidad y últimas novedades | Qualoom</title>
        <meta name="title" content="Actualidad y últimas novedades | Qualoom"/>
        <meta name="description" content="Aquí descubrirás todas las novedades y últimas noticias relacionadas con Qualoom Expertise Technology"/>

        <html lang='es'/>        <link rel="canonical" href=""/>
        <link rel="canonical" href="https://www.qualoom.es/actualidad/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>

        <meta property="og:title" content="Actualidad y últimas novedades | Qualoom"/>
        <meta property="og:description" content="Aquí descubrirás todas las novedades y últimas noticias relacionadas con Qualoom Expertise Technology"/>
        <meta property="og:url" content="https://www.qualoom.es/actualidad/"/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/7rhbNBOZ4sZMG96XSKiTMA/ac70f4d225e620f19387c691881b4a33/tw_cards_actualidad.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>

        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/7rhbNBOZ4sZMG96XSKiTMA/ac70f4d225e620f19387c691881b4a33/tw_cards_actualidad.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/actualidad/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Actualidad", "item": {"@id": "https://www.qualoom.es/actualidad/", "name": "Actualidad"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-actualidad">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Actualidad</h1>
              <p>En esta sección encontraras todas las novedades y últimas noticias relacionadas con Qualoom Expertise Technology y las nuevas tecnologías. También podrás acceder a todos los contenidos exclusivos creados por nuestro equipo.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="actualidad">
        <div className="actualidad-content-main">
          <div className="top">
            <div className="breadcrumbs" onClick={Last} onKeyDown aria-hidden>
              <FaHome size={20} color="#3B4757"/><p>Inicio</p>
            </div>
            <div className="category">
              <p onClick={News} onKeyDown aria-hidden className={noticia? "hover" : "normal"}>Noticias</p>
              <p onClick={Web} onKeyDown aria-hidden className={novedad? "hover" : "normal"}>Novedades</p>
              <p onClick={Contenidos} onKeyDown aria-hidden className={pdf? "hover" : "normal"}>Contenidos</p>
            </div>
          </div>
          <div className="actualidad-content">              
            <h2 id="title">Últimas noticias y novedades de Qualoom</h2>
            <div className="actualidad-main">
              <div onClick={NewsMobile} onKeyDown aria-hidden className="drop">
                Noticias <FaAngleDown size={20} color="#3B4757" className={noticia? "icon rotate" : "icon"}/>
              </div>
              <div className={nuevo? "show item-noticia" : "item-noticia"} id="last-news" onClick={Noticia38} onKeyDown aria-hidden>
                <div className="bg-image noticia38"><div className="date">NOV 2023</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">Garantizando la continuidad de negocio con un servicio de Soporte 24x7: Descubre las Características Clave e Importancia</div>
                <div className="item-text">En el vertiginoso mundo empresarial, donde la tecnología es la columna vertebral de cualquier operación, contar con un sólido servicio de soporte informático 24×7 se ha convertido en una necesidad imperante.</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia37} onKeyDown aria-hidden>
                <div className="bg-image noticia37"><div className="date">OCT 2023</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">Las 4 amenazas de ciberseguridad para las empresas</div>
                <div className="item-text">No cabe duda de que el siglo XXI ha traído una gran cantidad de beneficios para las compañías. La tecnología ha tenido un crecimiento impresionante en los últimos años y esto se demuestra con la ciberseguridad para las empresas.</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia36} onKeyDown aria-hidden>
                <div className="bg-image noticia36"><div className="date">OCT 2023</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">Esta es la razón por la que las empresas deben apoyarse en una consultoría tecnológica</div>
                <div className="item-text">La consultoría tecnológica desempeña un papel esencial en la era digital actual, asistiendo a las empresas en la toma de decisiones relacionadas con TIC</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia35} onKeyDown aria-hidden>
                <div className="bg-image noticia35"><div className="date">OCT 2023</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">La consultoría tecnológica en España sigue creciendo y Qualoom explica por qué es cada vez más necesario este servicio</div>
                <div className="item-text">Según la Asociación Española de Empresas de Consultoría (AEC), el sector de la consultoría tecnológica experimentó en 2022 un 15,2% más de ingresos que el año anterior</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia34} onKeyDown aria-hidden>
                <div className="bg-image noticia34"><div className="date">SEP 2023</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">España, uno de los países con menos brecha de seguridad informática en empresas</div>
                <div className="item-text">Las empresas de todo el mundo, sobre todo las empresas europeas, se enfrentan a amenazas constantes que buscan explotar vulnerabilidades en sus sistemas, robar datos sensibles y causar estragos en sus operaciones.</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia33} onKeyDown aria-hidden>
                <div className="bg-image noticia33"><div className="date">SEP 2023</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">Los riesgos en seguridad informática más graves para las empresas en 2023</div>
                <div className="item-text">¿Te imaginas que un programa malicioso se aloje en alguno de tus servidores y borre parte de la base de datos que tienes con información trascendental de tus clientes?</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia32} onKeyDown aria-hidden>
                <div className="bg-image noticia32"><div className="date">JUL 2023</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">¿Qué es una consultoría tecnológica y por qué las empresas deben contar con este servicio para crecer?</div>
                <div className="item-text"></div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia31} onKeyDown aria-hidden>
                <div className="bg-image noticia31"><div className="date">JUL 2023</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">Qualoom, la consultoría tecnológica con la que hacer crecer un negocio en el ámbito digital</div>
                <div className="item-text">Las empresas que buscan mantenerse a la vanguardia de la innovación y la eficiencia reconocen cada vez más la importancia de contratar los servicios de una consultoría tecnológica de confianza. </div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>  
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia30} onKeyDown aria-hidden>
                <div className="bg-image noticia30"><div className="date">JUN 2023</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">Seguridad informática: amenazas más comunes y la mejor estrategia de protección para empresas</div>
                <div className="item-text">En un mundo en el que las nuevas tecnologías forman parte de nuestra rutina habitual es esencial contar con la protección adecuada en los sistemas informáticos de cualquier empresa.</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>  
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia29} onKeyDown aria-hidden>
                <div className="bg-image noticia29a"><div className="date">MAY 2023</div></div>
                <div className="hide noticia29b"></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">La importancia del cloud computing en los procesos de transformación digital</div>
                <div className="item-text">La demanda de servicios en la nube en las empresas se ha disparado gracias a la agilidad y reducción de costes que supone, lo cual también ha generado desafíos a nivel de seguridad informática debido a sus vulnerabilidades</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>   
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia28} onKeyDown aria-hidden>
                <div className="bg-image noticia28"><div className="date">ABR 2023</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">La necesidad de la ciberseguridad en el proceso de transformación digital de las empresas</div>
                <div className="item-text">Muchas empresas y PYMEs no podían permitirse llevar a cabo un proceso de transformación digital. Debido a la pandemia y las ayudas procedentes del Kit Digital, promovidas tanto por la UE como por el Gobierno de España, ha dado pie a que multitud de negocios se vean capaces de dar el salto, obligados por el cambio en el modelo de consumo.</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>      
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia27} onKeyDown aria-hidden>
                <div className="bg-image noticia27"><div className="date">ABR 2023</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">La inversión en servicios Cloud sigue siendo uno de los principales objetivos para las empresas, según Qualoom</div>
                <div className="item-text">Cada vez son más los clientes y empresas que deciden apostar por estrategias y servicios en la nube para almacenar grandes cantidades de información en infraestructuras cloud, que son más seguras, amplias y confiables</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>       
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia26} onKeyDown aria-hidden>
                <div className="bg-image noticia26"><div className="date">MAR 2023</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">Las brechas de seguridad informática en las empresas españolas</div>
                <div className="item-text">Un informe de Eurostat arroja que casi una cuarta parte de las empresas europeas han tenido incidentes de tipo informático más allá incluso de los ciberataques.</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>      
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia25} onKeyDown aria-hidden>
                <div className="bg-image noticia25"><div className="date">MAR 2023</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">E-commerce, la práctica imprescindible para la transformación digital de las empresas</div>
                <div className="item-text">Para todas las empresas, ya sean grandes o pequeñas, es clave la digitalización de sus procesos, servicios y en términos de productos. Entre las muchas razones, una de ellas tiene que ver con términos de ahorro económico.</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>              
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia24} onKeyDown aria-hidden>
                <div className="bg-image noticia24"><div className="date">ENE 2023</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">Cómo conseguir un entorno Cloud suficientemente seguro con AWS</div>
                <div className="item-text">Los proveedores cloud genéricos garantizan una seguridad bastante limitada, que en muchos casos apenas alcanza los mínimos exigibles. Cada paso que damos hoy en día transcurre a través de aplicaciones y servicios cloud.</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia23} onKeyDown aria-hidden>
                <div className="bg-image noticia23"><div className="date">ENE 2023</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">Qualoom Expertise permite conocer el nivel de seguridad informática de una empresa con un sencillo test de autoevaluación</div>
                <div className="item-text">La ciberseguridad es uno de los servicios imprescindibles para cualquier empresa que opera en la red, pero también para la ciudadanía y las Administraciones Públicas.</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>
              <div className={noticia? "show item-noticia" : " hide item-noticia"} onClick={Noticia22} onKeyDown aria-hidden>
                <div className="bg-image noticia22"><div className="date">DIC 2022</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">Plugins que garantizan la seguridad en WordPress</div>
                <div className="item-text">Uno de los aspectos más importantes a la hora de garantizar la seguridad informática en WordPress es utilizar temas y plugins de confianza. Es importante no instalar demasiados plugins a la vez, ya que pueden ralentizar tu sitio.</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>
              <div className={noticia? "show item-noticia" : " hide item-noticia"} onClick={Noticia21} onKeyDown aria-hidden>
                <div className="bg-image noticia21"><div className="date">DIC 2022</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">DevOps, el modelo de colaboración, integración y administración de sistemas que revoluciona el desarrollo de los entornos digitales</div>
                <div className="item-text">La cultura DevOps es una filosofía de trabajo que busca unificar el desarrollo de software, y la administración de sistemas.</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia20} onKeyDown aria-hidden>
                <div className="bg-image noticia20"><div className="date">NOV 2022</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">Las 5 mejores consultoras tecnológicas en España</div>
                <div className="item-text">Esta empresa española es Partner Advanced en AWS y Microsoft con soluciones DevOps, SysOps, SysAdmin, proyectos de migración a la nube, Cloud híbrida, seguridad informática, planes de recuperación ante desastres, BigData, análisis de datos y optimización de alto rendimiento y escalabilidad.</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia19} onKeyDown aria-hidden>
                <div className="bg-image noticia19"><div className="date">OCT 2022</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">Qualoom, un referente en infraestructura y rendimiento para e-commerce</div>
                <div className="item-text">Qualoom, empresa especializada en consultoría tecnológica, apunta a la importancia de la aplicación de buenas prácticas en la administración y gobierno de una infraestructura atendiendo a las necesidades de cada empresa.</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia18} onKeyDown aria-hidden>
                <div className="bg-image noticia18"><div className="date">OCT 2022</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">Transformación Digital: Qué es y cómo pueden ayudarte empresas como Qualoom</div>
                <div className="item-text">Este cambio permite conseguir un gran potencial para ganar en un mundo cada vez más competitivo y digitalizado de la mano de empresas tecnológicas como Qualoom, que guían al cliente durante todo su proceso de transformación. </div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia17} onKeyDown aria-hidden>
                <div className="bg-image noticia17"><div className="date">OCT 2022</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">Cómo proteger tu sitio web de comercio electrónico de los piratas informáticos</div>
                <div className="item-text">Si te preocupa la ciberseguridad de tu negocio, puedes contratar un servicio de seguridad informática especializado que te ayude a reforzar tu presencia en línea contra los ciberataques.</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia16} onKeyDown aria-hidden>
                <div className="bg-image noticia16"><div className="date">SEP 2022</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">WPO Web: Cómo mejorar la velocidad de carga de tu web a través de plugins y el correcto rendimiento del hosting</div>
                <div className="item-text">Para mejorar los problemas de carga y revisar la configuración así como el rendimiento de los servidores que la ofrecen debemos ponernos en manos de expertos en la materia, como sucede en el caso de Qualoom Expertise Technology, especialistas en consultoría tecnológica.</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia15} onKeyDown aria-hidden>
                <div className="bg-image noticia15"><div className="date">SEP 2022</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">La importancia del diseño y evaluación de arquitecturas para los ecommerce</div>
                <div className="item-text">Hoy en día hay un tipo de comercio que está despuntando por encima de los modelos tradicionales, esto deviene de la incorporación de nuevos factores en el sector, como son  las nuevas tecnologías e internet.</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia14} onKeyDown aria-hidden>
                <div className="bg-image noticia14"><div className="date">JUL 2022</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">La digitalización de las empresas, un paso esencial para mejorar el rendimiento de negocios, según Qualoom</div>
                <div className="item-text">La presencia en Internet supone una mayor visibilidad para pequeñas y medianas empresas que pueden llegar a más clientes potenciales.</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia13} onKeyDown aria-hidden>
                <div className="bg-image noticia13"><div className="date">JUN 2022</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">Qualoom, una referencia en servicios Cloud y consultoría tecnológica</div>
                <div className="item-text">Empresas y particulares están expuestos a un sin fin de nuevas soluciones digitales, plataformas, aplicaciones y servicios de terceros que conllevan grandes avances y también grandes riesgos tecnológicos.</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia12} onKeyDown aria-hidden>
                <div className="bg-image noticia12"><div className="date">JUN 2022</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">La consultoría tecnológica, el servicio de las empresas del siglo XXI</div>
                <div className="item-text">En las empresas es una realidad imprescindible contar con los equipos informáticos, software y sistemas adecuados para que todo funcione a la perfección.</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia11} onKeyDown aria-hidden>
                <div className="bg-image noticia11"><div className="date">MAY 2022</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">El gran reto de la digitalización</div>
                <div className="item-text">La modernización de empresas y también del trabajo de autónomos tiene como gran reto en este siglo XXI dar el salto a la digitalización de sus negocios. Las ventajas de esta estrategia permiten a empresas y autónomos ser más eficientes y competitivos y mejorar así en sus resultados.</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia10} onKeyDown aria-hidden>
                <div className="bg-image noticia10"><div className="date">ABR 2022</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">La herramienta clave para las empresas del siglo XXI: la nube de datos</div>
                <div className="item-text">La pandemia y las necesidades de teletrabajo y digitalización de los negocios ha sido uno de los factores clave en la consolidación de esta herramienta, lo que ha hecho que cada vez sean más quienes deciden migrar a la nube sus datos e información.</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia9} onKeyDown aria-hidden>
                <div className="bg-image noticia9"><div className="date">MAR 2022</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">La seguridad informática: una cuestión más importante que nunca</div>
                <div className="item-text">Para evitar que nuestros datos y nuestra intimidad estén al alcance de hackers debemos prestar especial atención a la seguridad informática, ya que protegiéndonos adecuadamente será más difícil que accedan a ellos.</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia8} onKeyDown aria-hidden>
                <div className="bg-image noticia8"><div className="date">MAR 2022</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">Las empresas ya pueden presentar su solicitud para adherirse como Agentes Digitalizadores al programa Kit Digital</div>
                <div className="item-text">Las entidades interesadas en participar como “agente digitalizador adherido” pueden presentar desde este momento su solicitud en la sede de Red.es.</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia7} onKeyDown aria-hidden>
                <div className="bg-image noticia7"><div className="date">MAR 2022</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">La primera convocatoria de ayudas del programa Kit Digital comenzará el próximo 15 de marzo</div>
                <div className="item-text">A partir del próximo 15 de marzo las empresas interesadas podrán solicitar estas ayudas en la sede electrónica de Red.es y el plazo estará abierto durante 6 meses o hasta que se acaben los fondos.</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia6} onKeyDown aria-hidden>
                <div className="bg-image noticia6"><div className="date">FEB 2022</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">¿Qué es una consultoría tecnológica y cómo puede ayudar a empresas y autónomos a aumentar sus ventas y mejorar su operativa diaria?</div>
                <div className="item-text">Un ejemplo de ello es Qualoom, una de las consultoras tecnológicas líderes en el mercado español, especializada en apoyar los procesos de transformación digital de los negocios y en dar servicios de seguridad, como DevOps desarrollo de software, cloud datos y archivos en la nube e infraestructura digital.</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia5} onKeyDown aria-hidden>
                <div className="bg-image noticia5"><div className="date">ENE 2022</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">Qualoom ofrece una guía detallada sobre control y optimización de costes operativos en ecosistemas Cloud</div>
                <div className="item-text">Con motivo de la cuesta de enero, se antoja aún más importante recurrir a un plan preciso y eficaz destinado a la gestión y optimización de los costes en ecosistemas cloud; en Qualoom, son conscientes de ello y ofrecen algunas nociones para cumplir con este cometido con todas las garantías de precisión, seguridad y eficacia en el proceso.</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia4} onKeyDown aria-hidden>
                <div className="bg-image noticia4"><div className="date">DIC 2021</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">¿Cómo mantener a salvo tu negocio de un ataque informático?</div>
                <div className="item-text">Son muchos los aspectos que se deben controlar y las medidas que se deben llevar a cabo habitualmente para poder considerar que una página web es segura. En este artículo vamos a profundizar en ese tema. </div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia3} onKeyDown aria-hidden>
                <div className="bg-image noticia3"><div className="date">NOV 2021</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">Puesta a punto para el Black Friday</div>
                <div className="item-text">De todos los modelos de compra-venta, las compras online son las más habituales entre los consumidores, ahora más que nunca debido al cambio cultural como consecuencia de la pandemia. </div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia2} onKeyDown aria-hidden>
                <div className="bg-image noticia2"><div className="date">NOV 2021</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">La importancia de la ciberseguridad para proteger tu ecommerce</div>
                <div className="item-text">Cualquier empresa que gestiona bases de datos debe estar bien informada acerca de las medidas de prevención necesarias para mantener esa información protegida.</div>
                <div className="more">Sigue leyendo<hr /></div>
              </div>
              <div className={noticia? "show item-noticia" : "hide item-noticia"} onClick={Noticia1} onKeyDown aria-hidden>
                <div className="bg-image noticia1"><div className="date">OCT 2021</div></div>
                <div className="theme1">Noticia</div>
                <div className="item-title">La migración Cloud, uno de los servicios más demandados en la transformación digital, según apunta Qualoom</div>
                <div className="item-text">Qualoom, una de las compañías líderes en esta actividad, está especializada en proyectos de diseño, implantación y mantenimiento de infraestructuras en la gran nube, como Amazon Web Services, Azure y Google Cloud.</div>
                <div className="more">Sigue leyendo</div>
              </div>

              <div onClick={WebMobile} onKeyDown aria-hidden className="drop">Novedades <FaAngleDown size={20} color="#3B4757" className={novedad? "icon rotate" : "icon"}/></div>
              <div className={nuevo? "show item-novedad" : "item-novedad"} id="last-web">
                  <Link to="/blog/nagios-monitorizacion-y-autoalarmado/">
                  <div className="bg-image post51"><div className="date">DIC 2023</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Nagios: Cómo monitorizar su correcto funcionamiento</div>
                  <div className="item-text">Descubre Nagios, la herramienta de automatización para chequear entornos tecnológicos y recibir notificaciones efectivas de fallos.</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
              </div>
              <div className={nuevo? "show item-novedad" : "item-novedad"} id="last-web2">
                  <Link to="/blog/protegiendo-compras-online-black-friday-y-cyber-monday/">
                  <div className="bg-image post50"><div className="date">NOV 2023</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Protegiendo tus Compras en Línea durante el Black Friday y Cyber Monday</div>
                  <div className="item-text">Descubre cómo proteger tus compras en línea durante el Black Friday y Cyber Monday con nuestra guía detallada de prácticas seguras.</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
              </div>
              <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/mejores-herramientas-de-integracion-continua/">
                  <div className="bg-image post49"><div className="date">NOV 2023</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Las mejores herramientas de integración continua</div>
                  <div className="item-text">Descubre las mejores herramientas de integración continua para optimizar tu desarrollo de software. Evalúa características clave y conoce líderes como Jenkins, Bamboo, CircleCI y más. Simplifica tu proceso con la herramienta perfecta.</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
              </div>
              <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/principales-consecuencias-de-la-brecha-digital/">
                  <div className="bg-image post48"><div className="date">OCT 2023</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Las principales consecuencias de la brecha digital</div>
                  <div className="item-text">La brecha digital se entiende como la desigualdad en el acceso, uso y habilidades relacionadas entre diferentes grupos de personas o regiones con la tecnología de la información y comunicación (TIC) </div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
              </div>
              <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/sistemas-de-encriptacion-de-datos-en-reposo-en-aws-funcionamiento-e-integracion/">
                  <div className="bg-image post47"><div className="date">OCT 2023</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Sistemas de Encriptación de Datos en Reposo en AWS: Funcionamiento e Integración</div>
                  <div className="item-text">En la era digital actual, la seguridad de los datos es una preocupación crítica para las organizaciones que almacenan información sensible en la nube.</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
              </div>
              <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/monitorizacion-avanzada-entornos-de-seguridad-industrial-y-empresarial/">
                  <div className="bg-image post46"><div className="date">OCT 2023</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Monitorización Avanzada en Seguridad Industrial y Empresarial</div>
                  <div className="item-text">Descubre la importancia de la monitorización avanzada en los centros de seguridad de operaciones en el ámbito industrial</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
              </div>
              <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/cifrado-del-disco-con-bitlocker/">
                  <div className="bg-image post45"><div className="date">SEP 2023</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Cifrado del disco con Bitlocker</div>
                  <div className="item-text">Aprende sobre el cifrado de disco con BitLocker, una poderosa herramienta de seguridad para proteger tus datos en sistemas Windows.</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
              </div>
              <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/administracion-de-paquetes-python-en-entornos-linux-con-anaconda/">
                  <div className="bg-image post44"><div className="date">SEP 2023</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Administración de paquetes Python en entornos Linux con Anaconda</div>
                  <div className="item-text">Descubre Anaconda, la plataforma de código abierto para gestionar paquetes de software en Python. Simplifica tu administración con Qualoom.</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
              </div>
              <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/wazuh-mejor-siem-2023-alianza-qualoom/">
                  <div className="bg-image post43"><div className="date">SEP 2023</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Wazuh Best SIEM Solution 2023: Una alianza exitosa con Qualoom Expertise Technology</div>
                  <div className="item-text">Descubre por qué Wazuh es la Mejor Solución SIEM de 2023 y cómo su colaboración con Qualoom está redefiniendo la seguridad cibernética.</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
              </div>
              <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/migrando-el-correo-electronico-office-365/">
                  <div className="bg-image post42"><div className="date">AGO 2023</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Migrando el correo electrónico a Office 365</div>
                  <div className="item-text">Descubre cómo migrar a Office 365: optimiza colaboración y eficiencia. Evalúa métodos, configuraciones y consideraciones clave para una transición exitosa.</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
              </div>
              <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/introduccion-aws-cloudformation">
                  <div className="bg-image post41"><div className="date">JUL 2023</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Introducción a AWS CloudFormation</div>
                  <div className="item-text">CloudFormation es un servicio AWS para crear, implementar, administrar y monitorizar infraestructuras de computación en la nube de forma rápida y sencilla.</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
              </div>
              <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/que-es-la-migracion-de-datos/">
                  <div className="bg-image post40"><div className="date">JUL 2023</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">¿Qué es la migración de datos?</div>
                  <div className="item-text">La migración de datos implica transferir información de un sistema/plataforma existente (origen) a otro sistema/plataforma (destino), moviendo datos de una ubicación a otra, ya sea dentro o entre organizaciones.</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
              </div>
              <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/que-es-la-infraestructura-como-codigo/">
                  <div className="bg-image post39"><div className="date">JUN 2023</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">¿Qué es la Infraestructura como código (Infrastructure as Code)?</div>
                  <div className="item-text">La IaC es una técnica que permite crear y gestionar recursos informáticos mediante la definición de código, en lugar de realizarlo manualmente.</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
              </div>
              <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/que-es-el-pharming-informatico/">
                  <div className="bg-image post38"><div className="date">JUN 2023</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">¿Qué es y en qué consiste la técnica del pharming?</div>
                  <div className="item-text">En el mundo de la seguridad informática, el pharming es una técnica que los ciberdelincuentes utilizan para redirigir el tráfico de usuarios a páginas falsas sin su conocimiento ni consentimiento.</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
              </div>
              <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/inflacion-en-el-sector-tecnologico/">
                  <div className="bg-image post37"><div className="date">JUN 2023</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Inflación en el sector tecnológico: El impacto económico y las perspectivas futuras</div>
                  <div className="item-text">La inflación en tecnología tiene características distintas a la inflación general, ya que es un fenómeno que se centra en los productos y servicios tecnológicos</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
              </div>
              <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/colaboracion-mymoid-qualoom-proveedores-de-ciberseguridad-y-consultoria-tecnologica/">
                  <div className="bg-image post36"><div className="date">MAY 2023</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">MYMOID y Qualoom se unen en un acuerdo de colaboración como proveedores de Ciberseguridad y Consultoría Tecnológica</div>
                  <div className="item-text">Para mejorar el alcance de sus servicios, MYMOID comienza su colaboración con Qualoom, que pasa a formar parte de la red de expertos “MYMOID Experts”</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/suscripciones-para-la-deteccion-de-vulnerabilidades-de-seguridad/">
                  <div className="bg-image post35"><div className="date">MAY 2023</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Suscripciones a fuentes de información y organismos oficiales para la detección de vulnerabilidades de seguridad</div>
                  <div className="item-text">En este artículo, analizaremos las ventajas de mantener activas estas suscripciones de vulnerabilidades y cómo pueden ayudar a fortalecer la seguridad</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/estrategia-servicios-microsoft-azure/">
                  <div className="bg-image post34"><div className="date">MAY 2023</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Esprinet, Microsoft Iberia y Indimarketer junto con Qualoom lanzan una campaña estrategia de oferta de servicios en Microsoft Azure</div>
                  <div className="item-text">Desde Qualoom, junto con Microsoft Iberia, Esprinet e Indimarketers queremos anunciar el lanzamiento de una nueva campaña de oferta de servicios Cloud de Azure.</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/herramientas-ia-marketing-de-contenidos/">
                  <div className="bg-image post33"><div className="date">ABR 2023</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Herramientas de IA y su uso en el marketing de contenidos</div>
                  <div className="item-text">La Inteligencia Artificial está transformando la forma en que los profesionales del contenido producen, administran y entregan contenido de alta calidad.</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/lecturas-recomendadas-de-seguridad-informatica/">
                  <div className="bg-image post32"><div className="date">ABR 2023</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Recomendaciones para ampliar tus conocimientos en seguridad informática</div>
                  <div className="item-text">Ofrecemos un listado de lecturas recomendadas, desde libros especializados en seguridad a publicaciones sobre el uso de la tecnología para mejorarla.</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/contrasenas-seguras-para-tus-cuentas/">
                  <div className="bg-image post31"><div className="date">ABR 2023</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Contraseñas seguras para tus cuentas</div>
                  <div className="item-text">Una contraseña segura ayuda a evitar que los usuarios no autorizados accedan a la información sensible de tu organización.</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/ataques-informaticos-y-navegacion-segura/">
                  <div className="bg-image post30"><div className="date">MAR 2023</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Tipos de ataques informáticos y recomendaciones para una navegación segura</div>
                  <div className="item-text">En este nuevo artículo analizaremos los ataques informáticos más comunes y compartiremos recomendaciones esenciales para una navegación segura por internet.</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/diferencias-comandos-sudo-y-su-en-linux/">
                  <div className="bg-image post29"><div className="date">FEB 2023</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Diferencias entre los comandos sudo y su en Linux</div>
                  <div className="item-text">Estas herramientas proporcionan privilegios escalados, pero la forma en que lo hacen es significativamente diferente.</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/principales-proveedores-cloud/">
                  <div className="bg-image post28"><div className="date">FEB 2023</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Qué es el Cloud y cuales son los proveedores más destacados</div>
                  <div className="item-text">El cloud computing es una de las tendencias tecnológicas más importantes de la última década, un modelo de servicio online de recursos informáticos.</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/google-cloud-y-aws/">
                  <div className="bg-image post27"><div className="date">FEB 2023</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Google Cloud Platform vs. AWS</div>
                  <div className="item-text">La selección de servicios en la nube es una estrategia de TI común para la mayoría de las empresas, algunas utilizan múltiples cuentas de servicios en la nube.</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : " hide item-novedad"}>
                  <Link to="/blog/amazon-simple-storage-service/">
                  <div className="bg-image post26"><div className="date">ENE 2023</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">¿Qué es Amazon Simple Storage Service?</div>
                  <div className="item-text">Hoy vamos a realizar una introducción a Amazon Simple Storage Service (Amazon S3) uno de los muchos servicios de los que dispone AWS.</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/filesystem-hierarchy-standard-linux/">
                  <div className="bg-image post25"><div className="date">ENE 2023</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Estructura del árbol de directorios de Linux</div>
                  <div className="item-text">El Estándar de jerarquía del sistema de archivos (Filesystem Hierarchy Standard o FHS) define la estructura de los sistemas de archivos en Linux y otros sistemas operativos similares a UNIX. Sin embargo, los sistemas de archivos Linux también incluyen algunos directorios que no están definidos por el estándar.</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/arquitectura-wordpress-aws/">
                  <div className="bg-image post24"><div className="date">DIC 2022</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Diseño y despliegue de soluciones web (Wordpress, Prestashop, otros) en el Cloud AWS</div>
                  <div className="item-text">¿Tienes un sitio web o un ecommerce y deseas desplegarlo en un ecosistema Cloud como puede ser AWS, Google Cloud o Azure aplicando los criterios profesionales críticos y con garantías de que el planteamiento es el adecuado?</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/kpis-metricas-estrategias-de-marketing/">
                  <div className="bg-image post23"><div className="date">NOV 2022</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">KPIs a tener en cuenta para medir el rendimiento de las campañas de marketing</div>
                  <div className="item-text">Los indicadores o KPIs (Key Performance Indicator) son valores que se revisan periódicamente y permiten medir si se están cumpliendo los objetivos del plan y las campañas ejecutadas.</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/aplicaciones-monoliticas-y-soa/">
                  <div className="bg-image post22"><div className="date">NOV 2022</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Aplicaciones monolíticas y la arquitectura orientada a servicios (SOA)</div>
                  <div className="item-text">Este es uno de los puntos sobre los que se discute en los foros, la relación entre los microservicios y la arquitectura orientada a servicios (Service-Oriented Architecture), ¿son los microservicios una forma de implementar una SOA o son algo completamente distinto?</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/seo-poisoning-efectos-perjudiciales/">
                  <div className="bg-image post21"><div className="date">OCT 2022</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">SEO Poisoning: Envenenamiento SEO y sus efectos perjudiciales para tu sitio web</div>
                  <div className="item-text">El SEO poisoning o envenenamiento SEO se trata de una técnica en el ámbito del Black Hat SEO, utilizada tanto por sitios web maliciosos como legítimos, para obtener una alta clasificación en los motores de búsqueda para, más tarde, atacar a sus visitantes.</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/capacidad-de-infraestructura-y-sistemas-de-informacion-en-campanas-de-marketing/">
                  <div className="bg-image post20"><div className="date">SEP 2022</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">La importancia de la capacidad de la infraestructura y sistemas de información durante la ejecución de las campañas de marketing</div>
                  <div className="item-text">Todos conocemos la importancia que tiene la correcta definición de una Estrategia de Marketing Digital para llevar a término una exitosa campaña de venta de productos, servicios y de fidelización de usuarios.</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/montar-una-red-wifi-con-unifi/">
                  <div className="bg-image post19"><div className="date">SEP 2022</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">¿Cómo montar una red Wifi con Unifi?</div>
                  <div className="item-text">Hoy vamos a entrar en el mundo de las redes wifi-corporativas de la mano de Ubiquiti con sus productos Unifi. En este artículo nos vamos a centrar en el despliegue de una red wifi.</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/vpn-entre-pfsense-y-mikrotik/">
                  <div className="bg-image post18"><div className="date">AGO 2022</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">VPN LAN2LAN entre Pfsense y Mikrotik</div>
                  <div className="item-text">En este artículo vamos a dar una guía paso a paso de como configurar un servidor de OpenVPN sobre un appliance de Pfsense con el fin de conectar vía OpenVPN las distintas sedes de una empresa mediante VPNs LAN to LAN utilizando como cliente router Mikrotik.</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/ataques-informaticos/">
                  <div className="bg-image post17"><div className="date">JUN 2022</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">¿Qué es y cómo se produce un ataque informático?</div>
                  <div className="item-text">¿Cuáles son las fases y los tipos de ataques informáticos que podrías sufrir? ¿Cómo se puede solucionar un ataque informático a los sistemas?</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/cloud-hibrida/">
                  <div className="bg-image post16"><div className="date">JUN 2022</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">¿Qué es una nube híbrida? Ventajas y desventajas</div>
                  <div className="item-text">Podríamos definirlo como un entorno de computación que utiliza una combinación de servicios en instalaciones locales (on-premises), nube privada y servicios de nube pública. Normalmente, esto implica una conexión desde un centro de datos local a una nube pública.</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/inteligencia-artificial-usos-y-aplicaciones/">
                  <div className="bg-image post15"><div className="date">JUN 2022</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Usos y aplicaciones de la inteligencia artificial en la actualidad</div>
                  <div className="item-text">El uso de la Inteligencia Artificial en la vida cotidiana: ¿en qué ámbitos ha resultado más determinante?  ¿medicina, educación, medio ambiente? ¿Cuál es el futuro de la Inteligencia Artificial?</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/green-computing-estrategia-empresarial/">
                  <div className="bg-image post14"><div className="date">JUN 2022</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Green computing y otras soluciones eco-responsables para tu empresa</div>
                  <div className="item-text">El Green computing o Green IT es un conjunto de prácticas que se aplican a las estrategias utilizando las TICs para proteger los recursos energéticos.</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/certificacion-gcp-google-cloud-professional-engineer/">
                  <div className="bg-image post13"><div className="date">MAY 2022</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Cómo obtener tu certificación de Google Cloud Platform</div>
                  <div className="item-text">La implantación de tecnologías basadas en la nube crece cada año de forma imparable gracias a las ventajas que ofrecen. Cada año, miles de empresas a lo largo de todo el mundo se deciden a dar el paso y migrar sus proyectos a la nube.</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/gestionar-picos-de-trafico-en-web/">
                  <div className="bg-image post12"><div className="date">MAY 2022</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Gestionar los picos de tráfico en sitios web</div>
                  <div className="item-text">El aumento del tráfico de una web no está siempre relacionado con un crecimiento empresarial o económico. ¿Qué ocurriría con un aumento repentino en el tráfico?</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/gestion-de-la-configuracion-ansible/">
                  <div className="bg-image post11"><div className="date">ABR 2022</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Gestión de la configuración con Ansible</div>
                  <div className="item-text">Esta gestión consiste en centralizar la administración de todo un conjunto de servidores en un único nodo o varios para facilitar la administración del sistema</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/docker-instalacion-configuracion-y-uso/">
                  <div className="bg-image post10"><div className="date">FEB 2022</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Trabajando con Docker: Instalación, configuración y uso</div>
                  <div className="item-text">En los últimos años, Docker se ha revelado como una herramienta fundamental tanto para administradores de sistemas como para desarrolladores de software. Uno de los conflictos típicos a los que se enfrentan ambos perfiles viene dado por la heterogeneidad</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/partner-program/">
                  <div className="bg-image post9"><div className="date">FEB 2022</div></div>
                  <div className="theme2">Web</div>
                  <div className="item-title">Descubre nuestro nuevo Partner Program</div>
                  <div className="item-text">Un modelo de colaboración y partnership diferente creado por Qualoom Expertise Technology, efiaz, cuantificable y orientado al éxito y a la generación de negocio</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/openvpn-pfsense-configuracion/">
                  <div className="bg-image post8"><div className="date">FEB 2022</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Servidor de OpenVPN con PfSense. Configuración y uso</div>
                  <div className="item-text">En este artículo te mostramos una guía paso a paso para configurar un servidor de OpenVPN sobre un appliance de PfSense. Nos enfocaremos en el uso de la VPN para dar acceso a los empleados de tu empresa a la red corporativa.</div>
                  <div className="more">Sigue leyendo<hr /></div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/apache-openmeetings-instalacion-y-configuracion/">
                  <div className="bg-image post7"><div className="date">ENE 2022</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Apache OpenMeetings 6.2.0: Instalación, configuración y uso</div>
                  <div className="item-text">Sabemos que existen varias alternativas de software con posibilidades similares a las descritas más arriba, pero nos decantamos por OpenMeetings al tratarse de una herramienta de uso libre y gratuita, además de muy personalizable.</div>
                  <div className="more">Sigue leyendo<hr /></div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/backups-exchange-cloudberry/">
                  <div className="bg-image post6"><div className="date">DIC 2021</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Backups de Exchange con Cloudberry y restauración a nivel de correo</div>
                  <div className="item-text">Si quieres gestionar los datos en Microsoft Exchange con ayuda de Cloudberry te explicamos los pasos que debes seguir, además aprenderás a restaurar los datos a nivel de correo</div>
                  <div className="more">Sigue leyendo<hr /></div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/administracion-usuarios-roles-postgresql/">
                  <div className="bg-image post5"><div className="date">DIC 2021</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Administración de usuarios y de roles de PostgreSQL</div>
                  <div className="item-text">PostgreSQL permite conceder permisos directamente a los usuarios de la base de datos. Se recomienda crear varios roles con conjuntos específicos de permisos basados en los requisitos de aplicación y acceso</div>
                  <div className="more">Sigue leyendo<hr /></div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/mysql-optimizacion-y-rendimiento/">
                  <div className="bg-image post4"><div className="date">DIC 2021</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">MySQL Optimización y rendimiento de la base de datos</div>
                  <div className="item-text">Los problemas en MySQL dependen de muchos factores, y normalmente tenemos poco tiempo para analizar o investigar, máxime si estamos en mitad de una severidad. ¿Dónde tendría que poner mi atención para determinar la causa raíz del problema?</div>
                  <div className="more">Sigue leyendo<hr /></div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/devops/">
                  <div className="bg-image post3"><div className="date">NOV 2021</div></div>
                  <div className="theme2">Web</div>
                  <div className="item-title">Descubre nuestra nueva sección de servicios DevOps</div>
                  <div className="item-text">Conjunto de prácticas, métodos y herramientas que mejoran el marco colaborativo entre las áreas de operaciones, desarrollo, calidad y sistemas</div>
                  <div className="more">Sigue leyendo</div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/primeros-pasos-en-aws/">
                  <div className="bg-image post2"><div className="date">NOV 2021</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Introducción a Amazon Web Services. Primeros pasos</div>
                  <div className="item-text">Si te estás iniciando en AWS debes tener algunos conocimientos básicos para que tu iniciación en los servicios Cloud sea más sencilla</div>
                  <div className="more">Sigue leyendo<hr /></div>
                  </Link>
                </div>
                <div className={novedad? "show item-novedad" : "hide item-novedad"}>
                  <Link to="/blog/instalar-y-configurar-nagios-core-ubuntu/">
                  <div className="bg-image post1"><div className="date">NOV 2021</div></div>
                  <div className="theme2">Blog</div>
                  <div className="item-title">Instalación y configuración de Nagios Core en Ubuntu 21.04</div>
                  <div className="item-text">En este artículo encontraras todos los pasos necesarios para instalar y configurar correctamente la versión 21.04 de Nagios Core en tu sistema operativo de Linux</div>
                  <div className="more">Sigue leyendo<hr /></div>
                  </Link>
                </div>

              <div onClick={ContenidosMobile} onKeyDown aria-hidden className="drop">
                Contenidos <FaAngleDown size={20} color="#3B4757" className={pdf? "icon rotate" : "icon"}/>
              </div>
                <div className={pdf? "contenidos-item show" : "contenidos-item hide"}>
                  <p>Ya puedes suscribirte a nuestras newsletters para recibir periódicamente los nuevos contenidos de las siguientes temáticas:</p>
                  <div className="item-pdf">
                    <p>Cloud & Infraestructura</p>
                    <ul>
                      <li><span>Escenarios de desastre ecommerce</span><FaFilePdf size={20}/><a href="/documents/Qualoom%20Expertise%20Technology%20-%20Escenarios%20de%20desastre%20eCommerce.pdf" target="_blank">Descargar</a></li>
                      <li><span>Escenarios de pedidos masivos</span><FaFilePdf size={20}/><a href="/documents/Qualoom%20Expertise%20Technology%20-%20Escenarios%20de%20pedidos%20punta.pdf" target="_blank">Descargar</a></li>
                      <li><span>Monitorización de pedidos punta</span><FaFilePdf size={20}/><a href="/documents/Qualoom%20Expertise%20Technology%20-%20Monitorizacion%20de%20pedidos%20punta.pdf" target="_blank">Descargar</a></li>
                      <li><span>Herramientas de migraciones Cloud</span><FaFilePdf size={20}/><a href="/documents/Qualoom%20Expertise%20Technology%20-%20Herramientas%20de%20migraciones%20cloud.pdf" target="_blank">Descargar</a></li>
                      <li><span>Estrategias de migraciones Cloud</span><FaFilePdf size={20}/><a href="/documents/Qualoom%20Expertise%20Technology%20-%20Estrategias%20de%20migraciones%20cloud.pdf" target="_blank">Descargar</a></li>
                      <li><span>Gestión y optimización de costes</span><FaFilePdf size={20}/><a href="/documents/Qualoom%20Expertise%20Technology%20-%20Gestion%20y%20optimizacion%20de%20costes.pdf" target="_blank">Descargar</a></li>
                    </ul>
                    <Link to="/cloud-infraestructura/"><div className="item-button">Suscribirme</div></Link>
                  </div>
                  <div className="item-pdf">
                    <p>Servicios DevOps</p>
                    <ul><li><span>Metodología y buenas práctivas DevOps</span><FaFilePdf size={20}/><a href="/documents/Qualoom%20Expertise%20Technology%20-%20Metodologia%20DevOps.pdf" target="_blank">Descargar</a></li></ul>
                    <Link to="/devops/"><div className="item-button">Suscribirme</div></Link>
                  </div>
                  <div className="item-pdf">
                    <p>Seguridad informática</p>
                    <ul>
                      <li><span>Seguridad en ecommerce</span><FaFilePdf size={20}/>
                      <a href="/documents/Qualoom%20Expertise%20Technology%20-%20Seguridad%20en%20eCommerce.pdf" target="_blank">Descargar</a></li>
                      <li><span>Evalua la seguridad en tu organización</span><FaFilePdf size={20}/>
                      <a href="/documents/Qualoom%20Expertise%20Technology%20-%20Evalua%20la%20seguridad%20en%20tu%20organizacion.pdf" target="_blank">Descargar</a></li>
                      <li><span>Securización de entornos Cloud AWS</span><FaFilePdf size={20}/>
                      <a href="/documents/Qualoom%20Expertise%20Technology%20-%20Securizacion%20de%20entornos%20cloud%20AWS.pdf" target="_blank">Descargar</a></li>
                    </ul>
                    <Link to="/seguridad-informatica/"><div className="item-button">Suscribirme</div></Link>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Actualidad
